import Cookies from 'js-cookie';

export default class CookiesConsent {
  constructor() {
    this.services = [];
    this.cookie = this.getCookieConsentSetting();
  }

  init(services) {
    this.services = services;

    // If no cookie then let user choose what he wants to enable.
    document.addEventListener('DOMContentLoaded', () => {
      this.bindEnableAllButton();

      if (!this.cookie) {
        this.displayConsent();
      } else {
        // If user has already chosen some options, turn enabled services on
        this.setServices(services);
        // Run code of enabled services
        this.runEnabledServices();
      }

      this.createSettingsPage();
    });
  }

  setServices(services) {
    this.services = services;

    // Default is all disabled as it is required by GDPR.
    if (!this.cookie) {
      return this.services.forEach(service => {
        service.setEnabled(false);
      });
    }

    // Setting exists so turn on/off based on user preference
    this.services.forEach(service => {
      const cookieService = this.cookie.services.find(cookieService => cookieService.name == service.name);

      if (!cookieService) {
        return service.setEnabled(false);
      }

      return service.setEnabled(cookieService.enabled);
    });
  }

  displayConsent() {
    const consentBanner = document.getElementById('CookiesConsent');
    consentBanner.style.display = 'block';
    this.addBodyPadding(consentBanner);
  }

  hideConsent() {
    const consentBanner = document.getElementById('CookiesConsent');
    consentBanner.style.display = 'none';
    this.removeBodyPadding(consentBanner);
  }

  getCookieConsentSetting() {
    return Cookies.getJSON('cookiesConsent');
  }

  bindEnableAllButton() {
    const buttonEnableAll = document.getElementById('CookiesConsent-Button_enable_all');
    buttonEnableAll.addEventListener('click', () => {
      this.enableAll(false);
      this.runEnabledServices();
      this.hideConsent();
    });
  }

  enableAll(reload) {
    this.services.forEach(service => service.setEnabled(true));
    this.saveSettings(reload);
  }

  getServicesSettings() {
    return this.services.map(service => {
      return {
        name: service.name,
        enabled: service.getEnabled(),
      }
    });
  }

  runEnabledServices() {
    this.services.filter(service => service.getEnabled())
      .forEach(service => service.run());
  }

  createSettingsPage() {
    const page = document.getElementById('cookies-settings-page');

    if (!page) {
      return;
    }

    this.buildServices(page);
    this.bindSettingsPageButtons(page);
  }

  buildServices(page) {
    const servicesHtml = this.services.map(service => {
      return `
        <div class="CookiesSettingsPage-Service">
          <h2 class="CookiesSettingsPage-Name">${service.name}</h2>
          <div class="CookiesSettingsPage-Description">${service.description}</div>

          <div class="CookiesSettingsPage-Switch Switch">
            <span class="Switch-StateText_left">Vypnuté</span>
            <label class="Switch-Label">
              <input class="Switch-Input" type="checkbox" data-name="${service.name}" ${service.enabled ? 'checked' : ''} ${service.isAlwaysOn() ? 'disabled' : ''}>
              <span class="Switch-Slider"></span>
            </label>
            <span class="Switch-StateText_right">Zapnuté</span>
          </div>

          <div class="CookiesSettingsPage-Divider"></div>
        </div>
      `;
    });

    const list = page.querySelector('.CookiesSettingsPage-List');

    if (!list) {
      console.error('List not found');
    }

    list.innerHTML = servicesHtml.join('');
  }

  bindSettingsPageButtons(page) {
    const save = page.querySelector('#CookiesSettingsPage-Save');
    const enableAll = page.querySelector('#CookiesSettingsPage-EnableAll');
    const switches = page.querySelectorAll('.CookiesSettingsPage-Switch');

    if (save) {
      save.addEventListener('click', () => this.saveSettings(true));
    }

    if (enableAll) {
      enableAll.addEventListener('click', () => this.enableAll(true));
    }

    if (switches) {
      switches.forEach(toggleSwitch => {
        toggleSwitch.addEventListener('click', this.toggleSwitch.bind(this));
      });
    }
  }

  saveSettings(reload = false) {
    Cookies.set('cookiesConsent', { services: this.getServicesSettings() }, { expires: 7, path: '/', secure: true });

    if (reload) {
      window.location.reload();
    }
  }

  toggleSwitch(event) {
    const toggleServiceName = event.target.getAttribute('data-name');

    const service = this.services.find(service => service.name === toggleServiceName);
    service.setEnabled(!service.getEnabled());
  }

  addBodyPadding(consentBanner) {
    window.requestAnimationFrame(() => {
      const height = consentBanner.getBoundingClientRect().height;
      document.body.style.paddingBottom = Math.floor(height) + 'px';
    });
  }

  removeBodyPadding() {
    document.body.style.paddingBottom = 0;
  }
}

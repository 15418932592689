export default class TitleUnderline {
  init() {
    document.addEventListener('DOMContentLoaded', () => {
      this.wrapTablesWithContainer();
    });
  }

  wrapTablesWithContainer() {
    const tables = document.querySelectorAll('.ContentMatrix-Richtext table');
    tables.forEach((table) => {
      const wrapper = document.createElement('div');
      wrapper.classList.add('ContentMatrix-RichtextTableWrapper');
      table.parentNode.insertBefore(wrapper, table);
      wrapper.appendChild(table);
    });
  }
}

// @note if multilpe services wuld be present, extend generice service class with common methods.
export default class Default {
  constructor() {
    this.name = 'Povinné súbory cookies';
    this.description = `
        <p>Tieto súbory cookies sú nevihnutné pre správne a bezpečné fungovanie web stránky. Súbory sú uložené len vo vašom prehliadači.</p>
        <p>Tieto súbory sú povinne zapnuté.</p>
      `;
    this.enabled = true;
  }

  getEnabled() {
    return this.enabled;
  }

  setEnabled(enabled) {
    this.enabled = true;
  }

  isAlwaysOn() {
    return true;
  }

  run() {
    return;
  }
}

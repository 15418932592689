import throttle from 'lodash/throttle';

export default class HeroTextsWidthAdjust {
  init() {
    window.addEventListener('load', () => {
      this.setUpWidthAdjust();
      const throttled = throttle(this.setUpWidthAdjust.bind(this), 300);
      window.addEventListener('resize', throttled);
    });
  }

  setUpWidthAdjust() {
    const container = document.getElementById('Hero-TextsContainer');

    if (container === null) {
      return;
    }

    const bg = container.querySelector('.Hero-TextsBg');
    const cols = container.querySelectorAll('.row > .col');

    if (bg === null || cols === null) {
      return;
    }

    let contentWidth = null;

    cols.forEach(col => contentWidth += col.offsetWidth);

    bg.style.left = `${container.offsetLeft * -1}px`;
    bg.style.width = `${container.offsetLeft + contentWidth}px`;
  }
}

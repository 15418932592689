export default class Header {
  init() {
    document.addEventListener('DOMContentLoaded', () => {
      this.setUpOpening();
      this.setUpAccordeon();
    });
  }

  setUpOpening() {
    // Opening menu
    const hamburger = document.getElementById('Header-Hamburger');
    const navigationTop = document.getElementById('NavigationTopMobile');

    hamburger.addEventListener('click', () => {
      navigationTop.classList.toggle('NavigationTopMobile_open');
    });

    // Closing menu by clicking on overlay.
    const overlay = document.getElementById('NavigationTopMobile-Overlay');
    overlay.addEventListener('click', () => {
      navigationTop.classList.toggle('NavigationTopMobile_open');
    });
    // Closing menu by clicking on Close button.
    const close = document.getElementById('NavigationTopMobile-Close');
    close.addEventListener('click', () => {
      navigationTop.classList.toggle('NavigationTopMobile_open');
    });
  }

  setUpAccordeon() {
    const navigationTop = document.getElementById('NavigationTopMobile');
    // First level li-s
    const lis = navigationTop.querySelectorAll('.NavigationTopMobile-Wrapper > .NavigationTop-Ul > .NavigationTop-Li');
    lis.forEach((li) => {
      li.addEventListener('click', (event) => {
        this.toggleAccordeon(event, lis);
      });
    });
  }

  toggleAccordeon(event, lis) {
    const currentLi = event.currentTarget;
    const ul = currentLi.querySelector('.NavigationTop-Ul');

    if (event.target.getAttribute('href') === '#') {
      event.preventDefault();
    }

    // No submenu
    if (ul === null) {
      return;
    }

    ul.classList.toggle('NavigationTop-Ul_open');
    // It is open now
    const hasOpened = ul.classList.contains('NavigationTop-Ul_open');
    lis.forEach((li) => {
      this.toggleAccordeonDimmedClass(currentLi, li, hasOpened);
    })
  }

  toggleAccordeonDimmedClass(currentLi, li, hasOpened) {
    // Remove dimmed class if all submenus are closed
    if (!hasOpened) {
      li.classList.remove('NavigationTop-Li_dimmed');

      return;
    }

    // Skip currentLi
    if (li === currentLi) {
      li.classList.remove('NavigationTop-Li_dimmed')
      return;
    }

    li.classList.add('NavigationTop-Li_dimmed');
  }
}

import LazyLoad from "vanilla-lazyload";

export default class LazyLoadImages {
  init() {
    window.addEventListener('load', () => {
      this.lazyLoadInstance = new LazyLoad({
        // Your custom settings go here
      });
    });
  }
}

// Import style so webpack parses it and outputs it to seperate stylesheet file.
import style from '../scss/main.scss'

import Header from './header.js';
import NavigationTopMobile from './navigationTopMobile.js';
import NavigationTopDesktop from './navigationTopDesktop.js';
import TitleUnderline from './titleUnderline.js';
import HeroTextsWidthAdjust from './heroTextsWidthAdjust.js';
import WrapRichtextTablesWithContainer from './wrapRichtextTablesWithContainer.js';
import LazyLoadImages from './lazyLoadImages.js';
import CookiesConsent from './cookies/CookiesConsent.js';
import Default from './cookies/services/Default.js';
import GoogleAnalytics from './cookies/services/GoogleAnalytics.js';

(new Header()).init();
(new TitleUnderline()).init();
(new NavigationTopMobile()).init();
(new NavigationTopDesktop()).init();
(new HeroTextsWidthAdjust()).init();
(new WrapRichtextTablesWithContainer()).init();
(new LazyLoadImages()).init();
(new CookiesConsent()).init([
    new Default(),
    new GoogleAnalytics('UA-179933533-1'),
  ]);

// @note if multilpe services wuld be present, extend generice service class with common methods.
export default class GoogleAnalytics {
  constructor(propertyId) {
    this.name = 'Google Analytics';
    this.description = `
        <p>Cookies, pomocou ktorých vieme anonýmne sledovať internetovú premávku našich návštevníkov. Na základe týchto anonýmnych informácii vieme zlepšovať našu webstránku.</p>
        <p>Tieto súbory spracuváva tretia strana <a href="https://support.google.com/analytics/answer/6004245?hl=sk&ref_topic=2919631" target="_blank" rel="noreferrer noopener">Google Analytics</a>.</p>
        <p>Prijatím týchto súborov cookies nám dávate súhlas na ich zapnutie.</p>
      `;
    this.enabled = false;
    this.propertyId = propertyId;
  }

  getEnabled() {
    return this.enabled;
  }

  setEnabled(enabled) {
    this.enabled = enabled;
  }

  isAlwaysOn() {
    return false;
  }

  run() {
    if (document.body.getAttribute('data-dev-mode') == '1') {
      return;
    }

    // @note taken from here: https://developers.google.com/analytics/devguides/collection/analyticsjs
    (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
    (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
    m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
    })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

    ga('create', this.propertyId, 'auto');
    ga('send', 'pageview');
  }
}

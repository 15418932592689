import throttle from 'lodash/throttle';

export default class Header {
  init() {
    document.addEventListener('DOMContentLoaded', () => {
      this.setUpHeaderStyleChange();
    });
  }

  setUpHeaderStyleChange() {
    const body = document.querySelector('body');

    if (! body.classList.contains('homepage')) {
      return;
    }

    this.setHeaderStyle();
    const throttled = throttle(this.setHeaderStyle, 300);
    window.addEventListener('scroll', throttled);
  }

  setHeaderStyle() {
    const header = document.getElementById('Header');
    const transparentStyle = 'Header_style_transparent';

    if (window.scrollY === 0) {
      header.classList.add(transparentStyle);
    } else {
      header.classList.remove(transparentStyle);
    }
  }
}

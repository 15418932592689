export default class NavigationTopDesktop {
  init() {
    document.addEventListener('DOMContentLoaded', () => {
      this.setUpOpening();
      this.setUpCloseWhenClickedAway();
    });
  }

  setUpOpening() {
    // First level li-s
    const lis = document.querySelectorAll('#NavigationTopDesktop > .NavigationTop-Ul > .NavigationTop-Li');
    lis.forEach((li) => {
      li.addEventListener('click', (event) => {
        this.toggleSubmenu(event, lis);
      });
    });
  }

  toggleSubmenu(event, list) {
    event.stopPropagation();
    const currentLi = event.currentTarget;
    const currentUl = currentLi.querySelector('.NavigationTop-Ul');

    if (event.target.getAttribute('href') === '#') {
      event.preventDefault();
    }

    if (currentUl === null) {
      return;
    }

    currentUl.classList.toggle('NavigationTop-Ul_open');

    list.forEach((li) => {
      if (li == currentLi) {
        return;
      }

      const ul = li.querySelector('.NavigationTop-Ul');

      if (ul === null) {
        return;
      }

      ul.classList.remove('NavigationTop-Ul_open');
    });
  }

  setUpCloseWhenClickedAway() {
    document.addEventListener('click', () => {
      const openUls = document.querySelectorAll('#NavigationTopDesktop .NavigationTop-Ul_open');
      openUls.forEach((ul) => {
        ul.classList.remove('NavigationTop-Ul_open');
      });
    });
  }
}

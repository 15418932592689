import throttle from 'lodash/throttle';

export default class TitleUnderline {
  init() {
    window.addEventListener('load', () => {
      this.setUpUnderline();
      const throttled = throttle(this.setUpUnderline.bind(this), 300);
      window.addEventListener('resize', throttled);
    });
  }

  setUpUnderline() {
    const titles = document.querySelectorAll('.Title_style_underlined');

    titles.forEach((title) => {
      const underline = title.querySelector('.Title-Underline');

      if (!underline) {
        return;
      }

      const offsetLeft = this.getOffsetRelativeToBody(title);
      underline.style.left = `${offsetLeft * -1}px`;
      underline.style.width = `${title.offsetWidth - 64 + offsetLeft}px`;
    });
  }

  getOffsetRelativeToBody(domElement) {
    let offset = 0;
    let elem = domElement;

    do {
      if (isNaN(elem.offsetLeft)) {
        continue;
      }

      offset += elem.offsetLeft;
    } while (elem = elem.offsetParent);

    return offset;
  }
}
